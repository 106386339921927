import { hasNames } from "../../utils";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";



import Button from "../../components/button";
import Frame from "../../components/frame";
import { HeadMeta } from "../../components/head-meta";
import InternalHero from "../../components/internal-hero";
import NewsMoreCard from "../../components/news-more-card";



import * as styles from "../../styles/article.module.scss";
import * as grd from "../../styles/grid.module.scss";

export const Head = ({ pageContext }) => {
	return <HeadMeta title={pageContext.title} />;
};
const NewsArticlePage = ({ pageContext }) => {
	return (
		<Frame>
			<InternalHero
				eyebrow={pageContext.tags[0]}
				date={pageContext.date}
				backdrop={
					<StaticImage
						src={`../../images/heros/news.png`}
						alt="background image"
						aria-hidden="true"
						placeholder="blurred"
						layout="fullWidth"
					/>
				}>
				<h1>{pageContext.title}</h1>
			</InternalHero>
			<div className={grd.container}>
				<div
					className={hasNames(styles.content)}
					dangerouslySetInnerHTML={{ __html: pageContext.html }}></div>
			</div>
			<div className={hasNames(grd.container, styles.goBack)}>
				<Button
					type="tertiarySolid"
					to="/news">
					Return to News Listing
				</Button>
			</div>
			{pageContext.prev === undefined ? null : (
				<div className={hasNames(styles.nextprev, grd.gridContainer)}>
					<div className={grd.col12}>
						<h2 className={grd.col12}>More Cynosure News</h2>
					</div>
					<NewsMoreCard
						className={hasNames(grd.col6, grd.colSm12)}
						tag={pageContext.prev.tags[0]}
						title={pageContext.prev.title}
						link={"/news/" + pageContext.prev.slug}
					/>
					<NewsMoreCard
						className={hasNames(grd.col6, grd.colSm12)}
						tag={pageContext.next.tags[0]}
						title={pageContext.next.title}
						link={"/news/" + pageContext.next.slug}
					/>
				</div>
			)}
		</Frame>
	);
};

export default NewsArticlePage;
