import { hasNames } from "../utils";
import { Link } from "gatsby";
import * as React from "react";

import * as styles from "../styles/news-more-card.module.scss";

const NewsMoreCard = ({ tag, title, link, className }) => {
	return (
		<Link to={link} className={hasNames(className, styles.card)}>
			{tag ? <div className={styles.tag}>{tag}</div> : null}
			<h3>{title}</h3>
		</Link>
	);
};

export default NewsMoreCard;
